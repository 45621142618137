import { Fragment } from 'react';
import type { FC, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { ErrorBoundary } from 'next/dist/client/components/error-boundary';
import CrossIcon from '../icons/CrossIcon';
import { ErrorHandler } from '../ErrorHandler';

interface IModalProps extends React.PropsWithChildren {
	title?: ReactNode;
	isOpen: boolean;
	manualClose?: boolean;
	onCloseRequested: () => void;
	description?: ReactNode;
	maxWidth?: number;
	fullHeight?: boolean;
	className?: string;
}

const Modal: FC<IModalProps> = ({
	title,
	description,
	children,
	isOpen,
	manualClose = false,
	onCloseRequested,
	maxWidth = 540,
	className,
	fullHeight = false,
}) => {
	return (
		<ErrorBoundary errorComponent={ErrorHandler}>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 z-40" onClose={onCloseRequested}>
					<div className="min-h-screen">
						<Transition.Child
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
						</Transition.Child>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div
								style={{ maxWidth: `${maxWidth}px` }}
								className={clsx(
									className,
									'border border-gray-light overflow-auto p-10 text-left align-middle transition-all transform -translate-x-1/2 -translate-y-1/2 w-full bg-white fixed left-1/2 top-1/2 flex flex-col',
									fullHeight
										? 'h-screen md:h-[95vh]'
										: 'h-screen md:max-h-[70vh]',
								)}
							>
								{!manualClose && (
									<button
										onClick={onCloseRequested}
										className="absolute w-button h-button right-0 top-[env(safe-area-inset-top)] flex items-center justify-center hover:bg-black hover:bg-opacity-40"
									>
										<CrossIcon />
									</button>
								)}
								{title && (
									<Dialog.Title className="text-center font-serif font-bold text-lg text-brand mb-6">
										{title}
									</Dialog.Title>
								)}
								{description && (
									<Dialog.Description>{description}</Dialog.Description>
								)}
								{children}
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</ErrorBoundary>
	);
};

export default Modal;
