/**
 * Provides a way for the component tree to pass down a value to all children
 * that may need to get more refined the further down the tree it goes.
 *
 * For example, a "component location" situation may look like this:
 *
 * Root Area
 * 	- Product Carousel Area
 * 		- Product Tile Area
 * 			- Product Metadata Area
 *
 * Then, if you wanted to know where the user was when they clicked on a product
 * tile, you could use this context to pass down the location of the component like so:
 *
 * analytics.send({
 *   componentLocation: useSituation().componentLocation,
 * })
 *
 * and it would reflect where that component was placed in the tree.
 *
 * It's like regular React Context tree, but with merged values from all parents
 * rather than just clobbering the whole value and only allowing one value to be passed down.
 */

import { createContext, useContext } from 'react';

export interface SituationContextType extends Record<string, any> {}

export const defaultValue: SituationContextType = {};

export const SituationContext = createContext<SituationContextType>(defaultValue);

export const useSituation = function <T = {}>(): T {
	const context = useContext(SituationContext);

	if (!context) {
		throw new Error('useSituation must be used within a <Situation>');
	}

	return context as T;
};

export const Situation: React.FC<
	React.PropsWithChildren<{ value: Partial<SituationContextType> }>
> = ({ children, value }) => {
	const upstreamValue = useSituation();

	return (
		<SituationContext.Provider value={{ ...upstreamValue, ...value }}>
			{children}
		</SituationContext.Provider>
	);
};
