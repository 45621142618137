import React from 'react';
import Link, { LinkProps } from 'next/link';
import clsx from 'clsx';
import Spinner from '../Spinner';

export interface IButtonProps {
	type?: 'button' | 'submit' | 'reset';
	className?: string;
	onClick?: (event: React.MouseEvent) => void;
	href?: LinkProps['href'];
	isSubmitting?: boolean;
	submittingMessage?: string;
	disabled?: boolean;
	variant?: 'primary' | 'secondary' | 'destructive' | 'additive';
	noPadding?: boolean;
	isExternal?: boolean;
	rounded?: boolean;
	small?: boolean;
	[key: string]: any;
}

const Button: React.FC<IButtonProps> = ({
	className = '',
	onClick,
	type = 'button',
	href,
	children,
	isSubmitting = false,
	submittingMessage = 'Submitting',
	disabled = false,
	variant = 'primary',
	small = false,
	rounded = false,
	noPadding = false,
	isExternal = false,
	...otherProps
}) => {
	const classNames = clsx(
		`font-bold inline-flex items-center justify-center text-xs border-2 ring-offset-1 focus:ring-2 focus:outline-none`,
		className,
		{
			'py-2 px-4': small,
			uppercase: !small,
			'py-4 px-5': !noPadding && !small,
			'border-black bg-black text-white hover:bg-white hover:border-black hover:text-black focus:ring-black':
				variant === 'primary',
			'border-black bg-white text-black hover:bg-black hover:text-white focus:ring-black':
				variant === 'secondary',
			'border-red-500 bg-red-500 text-white hover:bg-transparent hover:text-red-500 focus:ring-red-500':
				variant === 'destructive',
			'border-green-500 bg-green-500 text-white hover:bg-transparent hover:text-green-500 focus:ring-green-500':
				variant === 'additive',
			'bg-gray-light border-gray-light cursor-not-allowed text-gray-medium hover:bg-gray-light hover:border-gray-light hover:text-gray-medium':
				disabled,
			'rounded-full': rounded,
		},
	);

	if (href) {
		return (
			<Link
				href={href}
				className={classNames}
				{...(isExternal ? { target: '_blank', rel: 'noreferrer noopener' } : {})}
				{...otherProps}
			>
				{children}
			</Link>
		);
	}

	return (
		<button
			onClick={onClick}
			className={classNames}
			type={type}
			disabled={disabled || isSubmitting}
			{...otherProps}
		>
			{isSubmitting ? (
				<>
					<Spinner height={`15px`} className={clsx(submittingMessage ? 'mr-3' : '')} />{' '}
					{submittingMessage}
				</>
			) : (
				children
			)}
		</button>
	);
};

export default Button;
