// as specified on https://vercel.com/loreal-b2b/b2b-public/settings/domains
const branchOnStaging = 'develop';

export const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV?.toString() === 'preview';
export const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV?.toString() === 'production';
export const isDevelopment = process.env.NEXT_PUBLIC_VERCEL_ENV?.toString() === 'development';
export const isStaging =
	process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF?.toString() === branchOnStaging;

// it's like a feature toggle util
export const useEnvAware = () => {
	return {
		isPreview,
		isProduction,
		isDevelopment,
		isStaging,
	};
};
