import * as React from 'react';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { createContext, useContext, useMemo, useState } from 'react';
import { CrossIcon } from '../icons';
import { IAlertContext, IGlobalAlert } from './types';

export const AlertContext = createContext<IAlertContext | null>(null);

export const useAlertContext = (): IAlertContext => {
	const c = useContext(AlertContext);

	if (!c) {
		throw new Error('useAlertContext must be used within an AlertContextProvider');
	}

	return c;
};

export const AlertContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [alerts, setAlerts] = useState<IAlertContext['alerts']>({});

	const dismissAlert = (alertId: string) => () => {
		setAlerts(state => {
			const newAlerts = { ...state };

			delete newAlerts[alertId];

			return newAlerts;
		});
	};

	const addAlert = (newAlert: IGlobalAlert) => {
		const alertId = nanoid();

		if (newAlert.timeout) {
			// TODO: incorporate useEffect to stop this from being called after unmount
			setTimeout(dismissAlert(alertId), newAlert.timeout);
		}

		setAlerts({
			...alerts,
			[alertId]: newAlert,
		});

		return alertId;
	};

	const alertList = useMemo(() => Object.entries(alerts), [alerts]);

	return (
		<AlertContext.Provider
			value={{
				alerts,
				addAlert,
				dismissAlert,
			}}
		>
			<ul className="pointer-events-none max-w-[540px] w-full fixed left-0 bottom-0 z-[100] grid auto-rows-max grid-flow-row">
				{alertList.map(([id, alertDetails]) => {
					return (
						<Transition
							appear
							show
							enter="transition ease-out delay-75 duration-100"
							enterFrom="transform -translate-x-full"
							enterTo="transform translate-x-0"
							leave="transition ease-in duration-75"
							leaveFrom="transform translate-x-0"
							leaveTo="transform -translate-x-full"
							as="li"
							key={id}
							className={clsx(
								'overflow-x-hidden text-sm pointer-events-auto py-0 m-2 px-2 rounded relative transform-gpu transition-transform border flex justify-between items-center',
								alertDetails.timeout ? 'pb-2' : '',
								{
									'bg-green-200 text-green-800 border-green-800':
										alertDetails.level === 'success',
									'bg-red-200 text-red-800 border-red-800':
										alertDetails.level === 'error',
									'bg-orange-200 text-orange-800 border-orange-800':
										alertDetails.level === 'warning',
									'bg-blue-200 text-blue-800 border-blue-800':
										alertDetails.level === 'info',
								},
							)}
						>
							<p>{alertDetails.contents}</p>
							<button
								onClick={dismissAlert(id)}
								className={clsx(
									'inline-flex items-center justify-center w-[44px] h-[44px] text-inherit',
									{
										'hover:bg-green-900 focus:bg-green-900':
											alertDetails.level === 'success',
										'hover:bg-red-900 focus:bg-red-900':
											alertDetails.level === 'error',
										'hover:bg-orange-900 focus:bg-orange-900':
											alertDetails.level === 'warning',
										'hover:bg-blue-900 focus:bg-blue-900':
											alertDetails.level === 'info',
									},
								)}
							>
								<CrossIcon />
							</button>
							{alertDetails.timeout && (
								<div
									className={clsx(
										'absolute bottom-0 left-0 w-full h-2 animate-slide-left bg-green-900',
										{
											'bg-green-900': alertDetails.level === 'success',
											'bg-red-900': alertDetails.level === 'error',
											'bg-orange-900': alertDetails.level === 'warning',
											'bg-blue-900': alertDetails.level === 'info',
										},
									)}
									style={{ animationDuration: `${alertDetails.timeout}ms` }}
								/>
							)}
						</Transition>
					);
				})}
			</ul>
			{children}
		</AlertContext.Provider>
	);
};
