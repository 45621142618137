var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3b0ceb7009724700e4d8b2aff73a6e507bfd46f8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
	dsn: 'https://83c5fb738f084e33bd294624f6f8aaf6@o315385.ingest.sentry.io/6362466',
	tracesSampleRate: 0.5,
	replaysOnErrorSampleRate: 0.5,
	integrations: [
		new BrowserTracing({
			tracingOrigins: [
				'localhost',
				'kostaging.io',
				'lorealorders.com.au',
				'lorealorders.co.nz',
			],
		}),
		new Sentry.Replay({
			networkDetailAllowUrls: [
				window.location.origin,
				'localhost',
				'kostaging.io',
				'lorealorders.com.au',
				'lorealorders.co.nz',
			],
		}),
	],
});
