import React from 'react';
import clsx from 'clsx';
import CheckIcon from '../icons/CheckIcon';

interface ICheckboxProps {
	checked: boolean;
	id: string;
	name: string;
	label: string | React.ReactNode;
	onChange: (newVal: boolean) => void;
	isError?: boolean;
	errorMessage?: string;
}

const Checkbox: React.FC<ICheckboxProps> = ({
	checked,
	name,
	id,
	label,
	onChange,
	isError = false,
	errorMessage = '',
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.checked;

		onChange(newValue);
	};

	return (
		<div>
			<div className="flex items-center">
				<div className="relative cursor-pointer">
					<input
						type="checkbox"
						className="peer absolute opacity-0 w-4 h-4"
						name={name}
						id={name}
						checked={checked}
						onChange={handleChange}
					/>
					<div
						className={clsx(
							'w-4 h-4 flex items-center text-transparent peer-checked:text-white peer-checked:border-brand justify-center text-white border peer-checked:bg-brand peer-focus:border-brand peer-focus:ring-2 peer-focus:ring-offset-1 peer-focus:ring-brand',
							isError ? 'border-red-500' : 'border-gray ',
						)}
					>
						<CheckIcon />
					</div>
				</div>
				<label htmlFor={id} className="ml-3 cursor-pointer text-xs select-none">
					{label}
				</label>
			</div>
			{isError && <span className="block mt-2 text-xs text-red-500">{errorMessage}</span>}
		</div>
	);
};

export default Checkbox;
